import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, OutlinedInput } from '@mui/material';
import { formatDateTime } from '../../utils/time';
import { formatMoney, formatSentence } from '../../utils/string';
import { SpanItem } from '../../components/fields/ItemDetail';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect, useState } from 'react';
import cashOut from '../../redux/actions/cash-out';
import { cashOutItemsColumns } from './models/columns';
import ModalDeleteBulk from '../../components/modal/ModalDeleteBulk';
import CashinInvoiceDialog from './CashinInvoiceDialog';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CashOutExportDialog from './CashOutExportDialog';
import { DeleteForever, Search } from '@mui/icons-material';
import { debounce } from 'lodash';
import DataTableDetail from '../../components/table/DataTableDetail';
import { LoadingButton } from '@mui/lab';
import { getDataCurrent } from '../../utils/token';
import { alertError, alertSuccess } from '../../utils/alert';

const CashOutDetailDialog = ({
  dataSelected,
  open,
  handleClose,
  handleRefetchDetail,
  handleRefetchList,
}: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashOut);
  const params = useAppSelector((state) => state.cashOut.paramsDataItems);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteBulk, setOpenDeleteBulk] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [dataInvoiceSelected, setDataInvoiceSelected] = useState<any>(null);
  const [modalExport, setModalExport] = useState<boolean>(false);
  const [listSelected, setListSelected] = useState<any[]>([]);

  const loadingResend = useAppSelector((state) => state.cashOut.isLoadingSubmit);

  const isSuperadmin = getDataCurrent().role_name === 'Superadmin';

  const resendEmail = async (id: any) => {
    handleClose();
    try {
      await dispatch(cashOut.resendEmail(id));
      alertSuccess('Email berhasil dikirim ulang');
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const doneManual = async (id: number) => {
    handleClose();
    try {
      await dispatch(cashOut.doneManual(id));
      alertSuccess(
        `Cashout with batch number ${dataSelected?.batch_number} has been done manually`,
      );
      handleRefetchList();
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  useEffect(() => {
    if (dataSelected) {
      dispatch(cashOut.fetchItems({ ...params, id: dataSelected.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected]);

  const handleDelete = (data: any) => {
    setDataInvoiceSelected(data);
    setOpenDelete(true);
  };

  const handleDeleteBulk = () => {
    setOpenDeleteBulk(true);
  };

  const handleRefetch = async () => {
    if (data.dataItems.length === 1) {
      handleClose();
      handleRefetchList();
      return () => {};
    } else {
      await handleRefetchDetail();
      return dispatch(cashOut.fetchItems({ ...params, id: dataSelected.id }));
    }
  };

  const handleDeleteInvoice = () => {
    const payload = {
      cash_out_id: dataSelected.id,
      cash_out_item_ids: [dataInvoiceSelected.id],
    };

    return cashOut.deleteInvoice(payload);
  };

  const handleDeleteBulkIvoice = () => {
    const payload = {
      cash_out_id: dataSelected.id,
      cash_out_item_ids: listSelected,
    };

    setListSelected([]);

    return cashOut.deleteInvoice(payload);
  };

  const handleSearch = debounce((e) => {
    dispatch(
      cashOut.fetchItems({
        ...params,
        id: dataSelected.id,
        page: 1,
        search: e.target.value,
      }),
    );
  }, 500);

  const onSelectTable = (values: any[]) => setListSelected(values);

  useEffect(() => {
    dispatch(cashOut.resetParamsDataItems());
    setListSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
          Transaction Detail
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item sm={6}>
              <SpanItem label="Partner Name" value={dataSelected?.partner_name} />
              <SpanItem label="Company Name" value={dataSelected?.company_name} />
              <SpanItem label="Bank Name" value={dataSelected?.partner_bank_name} />
              <SpanItem label="Bank Account Name" value={dataSelected?.partner_account_name} />
              <SpanItem label="Bank Account Number" value={dataSelected?.partner_account_number} />
              <hr />
              <SpanItem label="Subtotal Item" value={formatMoney(dataSelected?.sub_total)} />
              <SpanItem
                label="Transaction Fee"
                value={formatMoney(dataSelected?.transaction_fee)}
              />
              <SpanItem label="Discount" value={formatMoney(dataSelected?.total_voucher)} />
              <SpanItem label="Grand Total" value={formatMoney(dataSelected?.grand_total)} />
              <SpanItem label="Product Fee" value={formatMoney(dataSelected?.admin_fee)} />
              <SpanItem label="Cash Out Fee" value={formatMoney(dataSelected?.platform_fee)} />
              <SpanItem
                label="Total Disbursement"
                value={formatMoney(dataSelected?.total_disbursement)}
              />
            </Grid>
            <Grid item sm={6}>
              <SpanItem label="Batch Number" value={dataSelected?.batch_number} />
              <SpanItem label="Cash Out Status" value={formatSentence(dataSelected?.status, '-')} />
              <SpanItem label="Payment Provider" value={dataSelected?.provider_name} />
              <SpanItem label="Payment Channel" value={dataSelected?.channel_name} />
              <SpanItem
                label="Payment Schedule"
                value={formatDateTime(dataSelected?.disbursement_schedule_at)}
              />
              <hr />
              <SpanItem label="Created Date" value={formatDateTime(dataSelected?.created_at)} />
              <SpanItem label="Requested By" value={dataSelected?.requested_by} />
              <SpanItem label="Requested At" value={formatDateTime(dataSelected?.requested_at)} />
              <SpanItem label="Approved By" value={dataSelected?.approved_by} />
              <SpanItem label="Approved At" value={formatDateTime(dataSelected?.approved_at)} />
              <SpanItem label="Rejected By" value={dataSelected?.rejected_by} />
              <SpanItem label="Rejected At" value={formatDateTime(dataSelected?.rejected_at)} />
              <SpanItem label="Transferred" value={formatDateTime(dataSelected?.settlement_date)} />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid
              item
              sm={6}
              style={{ marginTop: '16px', marginBottom: '16px' }}
              justifyContent={'flex-start'}>
              {isSuperadmin && dataSelected?.status === 'ready' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => doneManual(dataSelected?.id)}
                  disabled={dataSelected?.status !== 'ready'}>
                  DONE MANUAL
                </Button>
              )}
            </Grid>
            <Grid item sm={6} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <OutlinedInput
                fullWidth
                onChange={handleSearch}
                placeholder="Search"
                endAdornment={<Search color="disabled" />}
                size="small"
              />
            </Grid>
          </Grid>
          <DataTableDetail
            data={data.dataItems}
            columns={cashOutItemsColumns(handleDelete, dataSelected?.status === 'ready')}
            fetchList={cashOut.fetchItems}
            params={data.paramsDataItems}
            pagination={data.paginationDataItems}
            loading={data.isLoadingItems}
            isCombinedSelected={true}
            isPagination
            isSelection={dataSelected?.status === 'ready'}
            selectedData={listSelected}
            showTotal
            onSelect={(val) => onSelectTable(val)}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          {listSelected.length > 0 && (
            <Button
              onClick={handleDeleteBulk}
              startIcon={<DeleteForever />}
              variant="contained"
              color="error"
              type="button">
              DELETE
            </Button>
          )}
          <Button onClick={handleClose} variant="outlined" type="button">
            CLOSE
          </Button>
          {dataSelected?.status === 'ready' && (
            <Button onClick={() => setOpenInvoiceDialog(true)} variant="contained" type="button">
              ADD INVOICE
            </Button>
          )}
          {dataSelected?.status === 'done' && (
            <LoadingButton
              loading={loadingResend}
              onClick={() => resendEmail(dataSelected?.id)}
              variant="contained"
              type="button">
              RESEND EMAIL
            </LoadingButton>
          )}
          <Button
            variant="contained"
            startIcon={<InsertDriveFileIcon />}
            onClick={() => setModalExport(true)}>
            EXPORT
          </Button>
        </DialogActions>
      </Dialog>
      <ModalDeleteBulk
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        deleteData={handleDeleteInvoice}
        fetchList={handleRefetch}
        data={data}
      />
      <ModalDeleteBulk
        open={openDeleteBulk}
        handleClose={() => setOpenDeleteBulk(false)}
        deleteData={handleDeleteBulkIvoice}
        fetchList={handleRefetch}
        data={data}
      />
      <CashinInvoiceDialog
        open={openInvoiceDialog}
        handleClose={() => setOpenInvoiceDialog(false)}
        partnerId={dataSelected?.partner_id || 0}
        companyProductId={dataSelected?.company_product_id || 0}
        id={dataSelected?.id || 0}
        fetchList={handleRefetch}
      />
      <CashOutExportDialog
        id={dataSelected?.id || ''}
        open={modalExport}
        handleClose={() => setModalExport(false)}
      />
    </div>
  );
};

export default CashOutDetailDialog;
