import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalActionProps {
  open: boolean;
  handleClose: () => void;
  handleActivate: () => void;
  handleDeactivate: () => void;
}

const ModalAction = ({ open, handleClose, handleActivate, handleDeactivate }: ModalActionProps) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <br></br>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          onClick={() => {
            handleActivate();
            handleClose();
          }}>
          Activate All Partner
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="warning"
          onClick={() => {
            handleDeactivate();
            handleClose();
          }}>
          Deactivate All Partner
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAction;
