import { BorderColor, Delete, History } from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from '../../../utils/time';
import { formatCapitalize } from '../../../utils/string';

export const emailTemplateColumns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
): GridColDef[] => {
  return [
    {
      field: 'company.name',
      headerName: 'Company',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.company?.name || '',
    },
    {
      field: 'partners.length',
      headerName: 'Partner',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.partners?.length || '0',
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      minWidth: 150,
      renderCell: (data) => (
        <Grid container alignItems={'center'}>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="info" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete" onClick={() => handleDelete(data.row)}>
              <Delete fontSize="small" className="pointer" color="error" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};

export const logEmailColumns = (
  role: any,
  handleHistory: (data: any) => void,
  handleResend: (data: any) => void,
): GridColDef[] => {
  return [
    {
      field: 'company.name',
      headerName: 'Company Name',
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row.company?.name || '',
    },
    { field: 'batch_number', headerName: 'Batch', flex: 1, minWidth: 150 },
    {
      field: 'partner.name',
      headerName: 'Partner Name',
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row.partner?.name || '',
    },
    {
      field: 'email',
      headerName: 'Address Mail',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'created_at',
      headerName: 'Date',
      flex: 1,
      minWidth: 200,
      renderCell: (data) => formatDateTime(data.row.created_at),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (data) => formatCapitalize(data.row.status),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      minWidth: 150,
      renderCell: (data) => (
        <Grid container alignItems={'center'}>
          {role.edit && (
            <Tooltip title="History" sx={{ mr: 1 }} onClick={() => handleHistory(data.row)}>
              <History fontSize="small" className="pointer" color="info" />
            </Tooltip>
          )}
          {role.delete && data.row.status === 'failed' && (
            <Button
              onClick={() => handleResend(data.row.id)}
              variant="contained"
              color="primary"
              size="small"
              sx={{ p: 0 }}>
              Resend
            </Button>
          )}
        </Grid>
      ),
    },
  ];
};

export const detaiLogEmailColumns = (): GridColDef[] => {
  return [
    {
      field: 'company.name',
      headerName: 'Company Name',
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row.company?.name || '',
    },
    { field: 'batch_number', headerName: 'Batch', flex: 1, minWidth: 250 },
    {
      field: 'partner.name',
      headerName: 'Partner Name',
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row.partner?.name || '',
    },
    {
      field: 'email',
      headerName: 'Address Mail',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'send_date',
      headerName: 'Timestamp',
      flex: 1,
      minWidth: 200,
      renderCell: (data) => formatDateTime(data.row.send_date),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (data) => formatCapitalize(data.row.status),
    },
    {
      field: 'error_message',
      headerName: 'Error Message',
      flex: 1,
      minWidth: 300,
    },
  ];
};
